import React from "react";

const InputSelectGroup = (props) => {
    const handleInput = (e) => {
        props.setValue(e)
    }

    return (
        <div className={"flex flex-row px-2 py-1 gap-1 items-center"}>
            <span className={"w-16 text-sm font-bold text-nowrap"}>{props.title}</span>
            <select className={"w-32 h-6 text-sm border rounded px-2"}
                    id={props.id}
                    name={props.name}
                    value={props.value}
                    onChange={handleInput}>
                {props.options.map((option, index) => {
                    return props.optionType === 'C' ? <option key={index} value={option.seq + ""}>{option.classNm}</option> : <option key={index} value={option.subCode}>{option.codeNm}</option>
                })}
            </select>
        </div>
    )
}

export default InputSelectGroup;